// @import './assets/fonts/fonts';

body {
  font-family: "Nunito Sans",-apple-system,".SFNSText-Regular","San Francisco",BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Helvetica,Arial,sans-serif;
}

html,
body,
div[id='root'] {
  margin: 0;
  padding: 0;
  border: 0;
  width: 100%;
  ::-webkit-datetime-edit-fields-wrapper {
    position: absolute;
    left: 12px;
    top: 35%;
  }
  ::-webkit-calendar-picker-indicator {
    position: absolute;
    right: 12px;
    top: 33%;
  }
  -webkit-tap-highlight-color: transparent;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-size: inherit;
  line-height: inherit;
}

*,
::after,
::before {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

p {
  margin: 0;
  padding: 0;
}

ul {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

li {
  list-style-type: none;
}

a {
  display: inline-block;
  text-decoration: none;
  color: inherit;
}

button {
  box-shadow: none;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.slide-enter-done {
  transform: translateX(0);
}

.Toastify__toast-container {
}
.Toastify__toast-body {
  background: none !important;
  box-shadow: none !important;
  border-radius: 50% !important;
}
.Toastify__toast {
  display: flex;
  box-shadow: none;
  color: none;
  overflow: visible;
  border-radius: 30px;
  width: 100%;
  overflow: visible;
  background: #363132 !important;
  border-radius: 12px !important;

  & > svg {
    position: absolute;
    right: 16px;
    top: 20px;
  }

  &--success {
  }

  &--error {
    // background: $color-orange;
  }

  &--info {
  }
}
.Toastify__toast-theme--light {
  background: #363132;
}

.Toastify__close-button {
  align-self: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: red;
  background: blue;

  & > svg {
    display: none;
  }

  // custom cross
  &:after {
    content: '\e80a';
  }
}

.swiper-pagination {
  @media (max-width: 800px) {
    display: none;
  }
}

.swiper-slide{
  height: auto !important;
}

.swiper-pagination-bullet {
  width: 16px !important;
  height: 16px !important;
  background: #c4c4c4 !important;
}

.swiper-pagination-bullet-active {
  background: #fdc740 !important;
}
.walletconnect-modal__mobile__toggle a {
  color:black;
}
//remove later
iframe{
  display: none !important;
}